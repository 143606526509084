import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";
import TableSticky from "components/TableSticky/TableSticky";

function PuzzleSessionDetail() {
  const { t } = useTranslation();
  const { sessionDetailSelected } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "pictureName", label: t("game.pictureName") },
    { key: "progress", label: t("game.progress") },
    { key: "spentTime", label: t("table.spentTime") },
  ];

  const convertDataToDataTable = (_data) => {
    if (_data.length === 0) return null;

    return _data.map((picture, index) => {
      return {
        order: index + 1,
        pictureName: picture.picName,
        progress: picture.completionRate,
        spentTime: picture.spentTime.toFixed(2),
      };
    });
  };

  return (
    <TableSticky
      headTabs={headTabs}
      data={convertDataToDataTable(sessionDetailSelected.pictures)}
    ></TableSticky>
  );
}

export default PuzzleSessionDetail;
