import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patients: null,
  patientsOfDoctor: null,
};

export const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setPatientsData: (state, action) => {
      state.patients = action.payload;
    },
    setPatientsOfDoctorData: (state, action) => {
      state.patientsOfDoctor = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setPatientsData, setPatientsOfDoctorData } =
  patientsSlice.actions;

export default patientsSlice.reducer;
