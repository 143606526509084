import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CreateDashboard from "pages/CreateDashboard/CreateDashboard";
import Dashboard from "pages/Dashboard/Dashboard";
import Login from "pages/Login/Login";
import MasterLayout from "layouts/MasterLayout";

function AppRoutes() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const bodyElement = document.body;

    if (i18n.language === "ja") {
      bodyElement.classList.add("japanese-font");
    } else {
      bodyElement.classList.remove("japanese-font");
    }
  }, [i18n.language]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("vtg_la") ?? "ja");

    const playFabUser = JSON.parse(localStorage.getItem("playFabUser"));
    if (!playFabUser) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/" element={<MasterLayout />}>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route
          path="/dashboard/create-dashboard"
          element={<CreateDashboard />}
        ></Route>
        <Route path="/" element={<Navigate to="/login" />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
