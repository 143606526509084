import axios from "axios";

// Tạo instance của Axios
const axiosInstance = axios.create({
  baseURL: window._env_.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Thiết lập interceptor để chèn Bearer Token vào mỗi yêu cầu
export const setupAxios = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      // Lấy token từ localStorage
      const vtgAuth = localStorage.getItem("vtg_auth");

      // Nếu có token, thêm vào header Authorization
      if (vtgAuth) {
        config.headers.Authorization = `Bearer ${vtgAuth}`;
      }
      return config;
    },
    (error) => {
      // Xử lý lỗi trong request
      return Promise.reject(new Error(error));
    }
  );

  axiosInstance.interceptors.response.use((response) => {
    // Khi nhận được phản hồi, có thể cập nhật cấu hình hoặc thực hiện các hành động khác
    let vtgConfig = localStorage.getItem("vtg_config");
    vtgConfig = vtgConfig ? JSON.parse(vtgConfig) : {};
    vtgConfig.needLogin = false;
    localStorage.setItem("vtg_config", JSON.stringify(vtgConfig));
    return response;
  });
};

export default axiosInstance;
