import PatientsOfDoctor from "components/PatientsOfDoctor/PatientsOfDoctor";
import AddPatientPopup from "pages/Dashboard/AddPatientPopup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PlusIcon from "assets/icons/plus.svg";

import { PatientProvider } from "contexts/patient-context/PatientContext";
import styles from "./CreateDashboard.module.scss";

function CreateDashboard() {
  const { t } = useTranslation();
  const patientsRedux = useSelector((state) => state.patientsRedux);

  const [keyPopup, setKeyPopup] = useState(0);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleClickCreateButton = () => {
    setOpenCreateModal(true);
  };

  useEffect(() => {
    setKeyPopup((pre) => pre + 1);
  }, [openCreateModal]);

  return (
    <div className={styles.management_patient_dashboard}>
      <div className={styles.management_patient_dashboard_header}>
        <div className={styles.title}>
          {`${t("create_dashboard_page.patientListWidget")} (${
            patientsRedux.patientsOfDoctor?.length ?? 0
          })`}
        </div>

        <button
          onClick={handleClickCreateButton}
          className={`${styles.add_patient_to_the_widget_btn} rounded button_primary`}
        >
          <img alt="plus" src={PlusIcon}></img>

          {t("button.addPatientToTheWidget")}
        </button>
      </div>
      <PatientProvider>
        <PatientsOfDoctor />
      </PatientProvider>

      {patientsRedux.patients && (
        <AddPatientPopup
          key={keyPopup}
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
        ></AddPatientPopup>
      )}
    </div>
  );
}

export default CreateDashboard;
