import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import NoDataImg from "assets/img/no-data.webp";

import styles from "./TableCus.module.scss";

function TableCus({ headTabs, data, headerStyles = [], columnStyles = [] }) {
  const { t } = useTranslation();

  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <Table borderless hover responsive className={styles.table_wrapper}>
        <thead className={styles.table_head}>
          <tr>
            {headTabs.map((tab) => {
              const style =
                headerStyles.find((style) => style.key === tab.key) || {};
              return (
                <th key={tab.key} align="center" style={style}>
                  {tab.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.table_body}>
          {data?.map((item) => (
            <tr key={item.key}>
              {headTabs.map((tab) => {
                const style =
                  columnStyles.find((style) => style.key === tab.key) || {};
                return (
                  <td key={tab.key} align="center" style={style}>
                    {item[tab.key]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>

      {(!data || data.length === 0) && (
        <div className={styles.table_nodata}>
          <div>
            <img src={NoDataImg} alt="nodata"></img>
            <div>{t("table.noData")}</div>
          </div>
        </div>
      )}
    </div>
  );
}

TableCus.propTypes = {
  headTabs: PropTypes.array,
  data: PropTypes.array,
  headerStyles: PropTypes.array,
  columnStyles: PropTypes.array,
};

export default TableCus;
