import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";

import GameDetailLayout from "../GameDetailLayout";

function NeckThrowDetail() {
  const { t } = useTranslation();
  const { gameSelected, setSessionDetailSelected } = usePatient();

  const headTabs = [
    { key: "order", label: t("game.practiceTimes") },
    { key: "spentTime", label: t("table.spentTime") },
    { key: "headUp", label: t("game.headUpCount") },
    { key: "headDown", label: t("game.headDownCount") },
    { key: "view", label: t("table.viewDetails") },
  ];

  const columnStyles = [
    {
      key: "view",
      textDecorationLine: "underline",
      color: "var(--text-color-blue)",
      cursor: "pointer",
    },
  ];

  const convertDataToDataTable = (_data) => {
    if (_data.length === 0) return null;

    return _data.map((session, index) => {
      return {
        order: index + 1,
        spentTime: session.spentTime.toFixed(2),
        headUp: session.lookDatas.filter((el) => el.name === "HeadUp").length,
        headDown: session.lookDatas.filter((el) => el.name === "HeadDown")
          .length,
        view: (
          <div
            onClick={() => {
              setSessionDetailSelected(session);
            }}
          >
            {t("table.viewDetails")}
          </div>
        ),
      };
    });
  };

  const totalSpentTime = gameSelected.result.neckMovementSessions
    .reduce((total, session) => {
      return total + session.spentTime;
    }, 0)
    .toFixed(0);

  return (
    <GameDetailLayout
      gameName={gameSelected.name}
      totalSpentTime={totalSpentTime}
      headTabs={headTabs}
      columnStyles={columnStyles}
      data={gameSelected.result.neckMovementSessions}
      dataConverter={convertDataToDataTable}
    />
  );
}

export default NeckThrowDetail;
