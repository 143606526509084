import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  userPublisherData: null,
  userData: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserPublisherData: (state, action) => {
      state.userPublisherData = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {})
      .addCase(login.fulfilled, (state, action) => {
        state.me = action.payload;
      })
      .addCase(login.rejected, (state, action) => {});
  },
});

export const login = createAsyncThunk("auth/login", async (params) => {
  try {
    const { loginId } = params;
    const response = await axios.post("/authenticate/login", {
      loginId: loginId,
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
});

export const { setUserPublisherData, setUserData } = authSlice.actions;

export default authSlice.reducer;
