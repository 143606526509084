import { useTranslation } from "react-i18next";
import TableSticky from "components/TableSticky/TableSticky";

import GameOverview from "./GameOverview";

import styles from "./index.module.scss";

function GameDetailLayout({
  gameName,
  totalSpentTime,
  headTabs,
  columnStyles,
  data,
  dataConverter,
}) {
  const { t } = useTranslation();
  console.log(columnStyles);
  const convertedData = dataConverter(data);

  return (
    <div className={styles.right_content}>
      <GameOverview name={gameName} totalSpentTime={totalSpentTime} />
      <div className={styles.game_detail}>
        <div className={styles.game_detail_title}>{`${t(
          "game.gameDetail"
        )}`}</div>
        <TableSticky
          headTabs={headTabs}
          data={convertedData}
          columnStyles={columnStyles}
        />
      </div>
    </div>
  );
}

export default GameDetailLayout;
