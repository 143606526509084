import { useEffect, useState } from "react";
import { useLoader } from "contexts/loader-context/LoaderContext";
import { usePatient } from "contexts/patient-context/PatientContext";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PaginationCus from "components/PaginationCus/PaginationCus";
import PatientDetailPopup from "pages/Dashboard/PatientDetailPopup";
import TableCus from "components/TableCus/TableCus";

function PatientsOfDoctor() {
  const { t } = useTranslation();
  const { toggleLoading } = useLoader();

  const { idPatient, setIdPatient, setUsernamePatient } = usePatient();

  const patientsRedux = useSelector((state) => state.patientsRedux);
  const metadataPage = {
    currentPage: 0,
    numberOfItems: patientsRedux.patientsOfDoctor?.length ?? 0,
    pageSize: 10,
  };

  const headTabs = [
    { key: "patientId", label: t("common_text.patientId") },
    { key: "fullName", label: t("common_text.fullName") },
    { key: "gender", label: t("common_text.gender") },
    { key: "age", label: t("common_text.age") },
    { key: "phoneNumber", label: t("common_text.phoneNumber") },
    { key: "detail", label: t("table.viewDetails") },
  ];
  const headerStyles = [
    { key: "patientId", fontWeight: "900" },
    { key: "fullName", textAlign: "center", fontWeight: "900" },
    { key: "gender", fontWeight: "900" },
    { key: "age", fontWeight: "900" },
    { key: "phoneNumber", fontWeight: "900" },
    { key: "detail", fontWeight: "900" },
  ];
  const columnStyles = [
    { key: "fullName", textAlign: "center", color: "var(--text-color-blue)" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [keyPopup, setKeyPopup] = useState(0);
  const [openPatientDetailModal, setOpenPatientDetailModal] = useState(false);

  const pageSize = 10;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData =
    patientsRedux.patientsOfDoctor?.slice(startIndex, endIndex) ?? [];

  const convertDataToDataTable = (_data) => {
    if (_data.length === 0) return null;

    return _data.map((user, key) => {
      const responseItem = patientsRedux.patients.find(
        (p) => p.id === user.master_id
      ).data.Data;

      return {
        key: responseItem["username"]?.Value ?? user.master_id,
        patientId: responseItem["username"]?.Value ?? user.master_id,
        fullName: responseItem["name"]?.Value ?? "",
        gender: responseItem["gender"]?.Value ?? "",
        age: responseItem["age"]?.Value ?? "",
        phoneNumber: responseItem["phone"]?.Value ?? "",
        detail: (
          <button
            style={{
              color: "var(--text-color-blue)",
              textDecorationLine: "underline",
              fontSize: "1vw",
            }}
            onClick={() => {
              idPatient !== user.master_id && toggleLoading(true);
              setUsernamePatient(
                responseItem["username"]?.Value ?? user.master_id
              );
              setIdPatient(user.master_id);
              setOpenPatientDetailModal(true);
            }}
          >
            {t("table.viewDetails")}
          </button>
        ),
      };
    });
  };

  useEffect(() => {
    setKeyPopup((pre) => pre + 1);
  }, [openPatientDetailModal]);

  return (
    <div>
      {paginatedData && patientsRedux.patients?.length > 0 && (
        <>
          <TableCus
            headTabs={headTabs}
            data={convertDataToDataTable(paginatedData)}
            headerStyles={headerStyles}
            columnStyles={columnStyles}
          ></TableCus>
          <PaginationCus
            metadataPage={metadataPage}
            onClick={(pageParam) => {
              setCurrentPage(pageParam.page);
            }}
            styles={{
              marginTop: "20px",
            }}
          />
          {idPatient && (
            <PatientDetailPopup
              key={keyPopup}
              openModal={openPatientDetailModal}
              setOpenModal={setOpenPatientDetailModal}
            />
          )}
        </>
      )}
    </div>
  );
}

export default PatientsOfDoctor;
