import { GROUP_ID_DEV } from "helps/Var";
import { LANGUAGE_CODES, LANGUAGE_NAMES } from "helps/Languages";
import { toast } from "react-toastify";
import { useLoader } from "contexts/loader-context/LoaderContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Popover from "components/Popover/Popover";

import ArrowDownIcon from "assets/icons/arrow-down.svg";
import CheckIcon from "assets/icons/check.svg";
import LanguageIcon from "assets/icons/language.svg";
import MiraboImg from "assets/img/mirabo-logo.webp";

import {
  applyToGroup,
  getUserPublisherDataFromPlayFab,
  updateUserPublisherDataToPlayFab,
} from "utils/playFabApi";

import AnhLoginImg from "assets/img/anh-login.webp";

import styles from "./Login.module.scss";

function Login() {
  const { i18n } = useTranslation();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toggleLoading } = useLoader();
  const [customId, setCustomId] = useState("");

  const playFabUser = JSON.parse(localStorage.getItem("playFabUser"));

  const [isDropdownLanguageOpen, setIsDropdownLanguageOpen] = useState(false);
  const [anchorElRegion, setAnchorElRegion] = useState(null);

  const onChangeLanguage = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("vtg_la", value);
  };

  const handleRegionClick = (e) => {
    setAnchorElRegion(e.currentTarget);
    setIsDropdownLanguageOpen(true);
  };

  const handleCloseRegion = () => {
    setAnchorElRegion(null);
    setIsDropdownLanguageOpen(false);
  };

  const handleLogin = () => {
    toggleLoading(true);
    const loginRequest = {
      CustomId: customId,
      CreateAccount: true,
    };

    window.PlayFabClientSDK.LoginWithCustomID(loginRequest, loginCallback);
  };

  const loginCallback = (result) => {
    if (result.status === "OK") {
      toggleLoading(false);
      const userData = {
        PlayFabId: result.data.PlayFabId,
        SessionTicket: result.data.SessionTicket,
        EntityToken: result.data.EntityToken.EntityToken,
        Username: customId,
      };

      localStorage.setItem("playFabUser", JSON.stringify(userData));
      const updatePublisherDataPromise = new Promise((resolve, reject) => {
        const playFabUser = JSON.parse(localStorage.getItem("playFabUser"));

        getUserPublisherDataFromPlayFab({
          playFabId: playFabUser.PlayFabId,
          sessionTicket: playFabUser.SessionTicket,
          getUserPublisherDataCallback: (result) => {
            if (result.status === "OK") {
              console.log("Lấy dữ liệu public thành công:", result);
              const transformedUserInfo = Object.fromEntries(
                Object.entries(result.data.Data).map(([key, { Value }]) => [
                  key,
                  Value,
                ])
              );

              transformedUserInfo.username = customId;
              updateUserPublisherDataToPlayFab({
                sessionTicket: playFabUser.SessionTicket,
                data: transformedUserInfo,
                updateUserPublisherDataCallback: (result) => {
                  if (result.status === "OK") {
                    console.log(
                      "Cập nhật dữ liệu publisher thành công:",
                      result
                    );
                    resolve();
                  } else {
                    console.error(
                      "Lỗi cập nhật dữ liệu publisher:",
                      result.error
                    );
                    reject(result.error);
                  }
                },
              });
            } else {
              console.error("Error get publisher data:", result.error);
              reject(result.error);
            }
          },
        });
      });

      const applyToGroupPromise = new Promise((resolve, reject) => {
        applyToGroup({
          entityToken: userData.EntityToken,
          groupId: GROUP_ID_DEV, // ID của nhóm dev
          applyCallback: (result) => {
            if (
              result.status === "OK" ||
              result.errorMessage.endsWith("is already a member of this group")
            ) {
              console.log("Người dùng đã apply vào nhóm thành công:", result);
              resolve();
            } else {
              console.error("Lỗi khi apply vào nhóm:", result.errorMessage);
              reject(result.errorMessage);
            }
          },
        });
      });

      // Wait for both promises to complete before navigating
      Promise.all([updatePublisherDataPromise, applyToGroupPromise])
        .then(() => {
          console.log("Both operations completed successfully");
          navigate("/dashboard");
        })
        .catch((error) => {
          console.error(
            "An error occurred during one of the operations:",
            error
          );
        });
    } else {
      toggleLoading(false);
      toast.error("Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const openRegion = Boolean(anchorElRegion);
  const idRegion = openRegion ? "region-popover" : undefined;

  return (
    <div className={styles.login_container}>
      <div className={styles.login_left}>
        <img src={AnhLoginImg} alt="Anh login" />
      </div>

      <div className={styles.login_right}>
        <>
          <button
            className={`${styles.language_btn} rounded`}
            onClick={handleRegionClick}
          >
            <img
              style={{
                objectFit: "cover",
              }}
              src={LanguageIcon}
              alt="Globe icon"
            />
            <span className={`size_small`} style={{ textWrap: "nowrap" }}>
              {LANGUAGE_NAMES[localStorage.getItem("vtg_la") ?? "ja"]}
            </span>
            <img src={ArrowDownIcon} alt="t" />
          </button>

          <Popover
            id={idRegion}
            open={openRegion}
            anchorEl={anchorElRegion}
            onClose={handleCloseRegion}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div
              className={`${styles.language_options} ${
                isDropdownLanguageOpen ? styles.open : styles.closed
              }`}
            >
              {Object.entries(LANGUAGE_CODES).map(([key, value]) => (
                <button
                  key={key}
                  className={`${styles.language_option} ${
                    i18n.language === value ? styles.selected : ""
                  }`}
                  onClick={() => {
                    updateUserPublisherDataToPlayFab({
                      sessionTicket: playFabUser.SessionTicket,
                      data: { language: value === "vi" ? "vi-VN" : value },
                      updateUserPublisherDataCallback: (result) => {
                        console.log("");
                      },
                    });
                    onChangeLanguage(value);
                  }}
                >
                  <span>{LANGUAGE_NAMES[value]}</span>
                  {i18n.language === value && (
                    <img src={CheckIcon} alt="icon" />
                  )}
                </button>
              ))}
            </div>
          </Popover>
        </>
        <div className={styles.login_form}>
          <img src={MiraboImg} alt="Product Logo" className={styles.logo} />
          <h1 className={`size_large`}>{t("login_page.loginTitle")}</h1>

          <label htmlFor="code-input" className={`${styles.label} size_medium`}>
            {t("common_text.enterTheCode")}
          </label>
          <input
            type="text"
            id="code-input"
            className={`${styles.input} size_medium`}
            placeholder={t("common_text.enterTheDoctorCode")}
            value={customId}
            onChange={(e) => setCustomId(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleLogin()}
          />
          <button
            className={`${styles.login_button} button_primary btn-large rounded`}
            onClick={handleLogin}
          >
            {t("login_page.login")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
