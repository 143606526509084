import {
  setPatientsData,
  setPatientsOfDoctorData,
} from "appdata/patients/patientsSlice";
import { useEffect } from "react";
import { GROUP_ID_DEV } from "helps/Var";

import {
  acceptAllGroupApplications,
  getGroupMembersFromPlayFab,
  getUserDataFromPlayFab,
  getUserPublisherDataFromPlayFab,
  listGroupApplications,
} from "utils/playFabApi";
import { useDispatch } from "react-redux";

const useUpdateUserDataFromPlayfab = () => {
  const dispatch = useDispatch();
  const groupId = GROUP_ID_DEV;
  const playFabUser = JSON.parse(localStorage.getItem("playFabUser"));

  const getGroupMembersCallback = (result) => {
    if (result.status === "OK") {
      const members = result.data.Members.find(
        (member) => member.RoleId === "members"
      ).Members;
      // Tạo một mảng các promise để lấy dữ liệu của từng thành viên
      const memberPromises = members.map((member) => {
        return new Promise((resolve, reject) => {
          const master_id = member.Lineage.master_player_account.Id;
          getUserPublisherDataFromPlayFab({
            playFabId: master_id,
            sessionTicket: playFabUser.SessionTicket,
            getUserPublisherDataCallback: (result) => {
              if (result.status === "OK") {
                resolve({ ...result, id: master_id, title_id: member.Key.Id });
              } else {
                console.error(
                  "Lỗi khi lấy dữ liệu cho thành viên:",
                  master_id,
                  result.errorMessage
                );
                reject(result.errorMessage);
              }
            },
          });
        });
      });

      Promise.all(memberPromises)
        .then((userDataArray) => {
          console.log(
            "Dữ liệu người dùng của tất cả thành viên:",
            userDataArray
          );
          dispatch(setPatientsData(userDataArray));
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu người dùng:", error);
        });
    } else {
      console.error("Lỗi khi lấy danh sách thành viên:", result.errorMessage);
    }
  };

  const handleListApplications = (
    result,
    entityToken,
    groupId,
    getGroupMembersCallback
  ) => {
    if (result.status === "OK") {
      const applications = result.data.Applications; // Danh sách các yêu cầu tham gia
      acceptAllGroupApplications({
        entityToken,
        groupId,
        applications,
        individualAcceptCallback: handleIndividualAccept,
        acceptCallback: (result) =>
          handleAcceptAll(
            result,
            entityToken,
            groupId,
            getGroupMembersCallback
          ),
      });
    } else {
      console.error("Lỗi khi lấy danh sách yêu cầu:", result.errorMessage);
    }
  };

  // Callback to handle group member retrieval
  const handleGetGroupMembers = (
    entityToken,
    groupId,
    getGroupMembersCallback
  ) => {
    getGroupMembersFromPlayFab({
      entityToken,
      groupId,
      getGroupMembersCallback,
    });
  };

  // Callback to handle individual application acceptance
  const handleIndividualAccept = (result) => {
    if (result.status === "OK") {
      console.log("Đã chấp nhận yêu cầu cho:", result);
    } else {
      console.error("Lỗi khi chấp nhận yêu cầu:", result.errorMessage);
    }
  };

  // Callback to handle acceptance of all applications
  const handleAcceptAll = (
    result,
    entityToken,
    groupId,
    getGroupMembersCallback
  ) => {
    if (
      (result.status === "OK" && typeof result === "object") ||
      Array.isArray(result)
    ) {
      console.log("Đã chấp nhận tất cả các yêu cầu:", result);
      handleGetGroupMembers(entityToken, groupId, getGroupMembersCallback);
    } else {
      console.error("Lỗi khi chấp nhận các yêu cầu:", result.errorMessage);
    }
  };

  useEffect(() => {
    let authOfAdmin = null;

    if (!playFabUser) {
      return;
    }

    const loginCallback = (result) => {
      if (result.status === "OK") {
        authOfAdmin = {
          PlayFabId: result.data.PlayFabId,
          SessionTicket: result.data.SessionTicket,
          EntityToken: result.data.EntityToken.EntityToken,
        };

        const entityToken = authOfAdmin.EntityToken;
        listGroupApplications({
          entityToken, // Entity Token của quản trị viên
          groupId, // ID của nhóm mà bạn muốn lấy danh sách yêu cầu
          listCallback: (result) =>
            handleListApplications(
              result,
              entityToken,
              groupId,
              getGroupMembersCallback
            ),
        });
      } else {
        console.error("Login failed:", result.errorMessage);
      }
    };

    const loginRequest = {
      CustomId: "THN02",
    };

    window.PlayFabClientSDK.LoginWithCustomID(loginRequest, loginCallback);

    const getUserDataCallback = (result) => {
      if (result.status === "OK") {
        dispatch(
          setPatientsOfDoctorData(
            result.data.Data.patients
              ? JSON.parse(result.data.Data.patients.Value)
              : null
          )
        );
      } else {
        if (result.code === 401) {
          localStorage.removeItem("playFabUser");
          window.location.href = "/login";
        }
        console.log(result?.errorMessage);
      }
    };

    getUserDataFromPlayFab({
      playFabId: playFabUser.PlayFabId,
      sessionTicket: playFabUser.SessionTicket,
      keys: ["patients"],
      getUserDataCallback,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useUpdateUserDataFromPlayfab;
