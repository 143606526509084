import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./auth/authSlice";
import gamesSlice from "./games/gamesSlice";
import patientsSlice from "./patients/patientsSlice";

export const store = configureStore({
  reducer: {
    authRedux: authSlice,
    gamesRedux: gamesSlice,
    patientsRedux: patientsSlice,
  },
});
