import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";
import MathDetail from "components/GameDetail/MathDetail/MathDetail";
import NeckThrowDetail from "components/GameDetail/NeckThrowDetail/NeckThrowDetail";
import NeckThrowSessionDetail from "components/GameDetail/NeckThrowDetail/NeckThrowSessionDetail";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import PuzzleDetail from "components/GameDetail/PuzzleDetail/PuzzleDetail";
import PuzzleSessionDetail from "components/GameDetail/PuzzleDetail/PuzzleSessionDetail";
import TmtADetail from "components/GameDetail/TmtADetail/TmtADetail";

import LeftArrowIcon from "assets/icons/left-arrow.svg";
import NoDataImg from "assets/img/no-data.webp";

import styles from "./PatientDetailPopup.module.scss";

function PatientDetailPopup({ openModal, setOpenModal }) {
  const { t } = useTranslation();

  const {
    usernamePatient,
    results,
    gameSelected,
    sessionDetailSelected,
    setGameSelected,
    setSessionDetailSelected,
  } = usePatient();

  const renderGameDetails = () => {
    switch (gameSelected?.id) {
      case "tmtA":
        return <TmtADetail />;
      case "tmtB":
        return <TmtADetail />;
      case "puzzle":
        return <PuzzleDetail />;
      case "neck_throw":
        return <NeckThrowDetail />;
      case "math":
        return <MathDetail />;
      default:
        return <></>;
    }
  };

  const renderSessionDetails = () => {
    switch (gameSelected?.id) {
      case "puzzle":
        return <PuzzleSessionDetail />;
      case "neck_throw":
        return <NeckThrowSessionDetail />;
      default:
        return <></>;
    }
  };

  return (
    <Popup
      style={{ zIndex: 1001 }}
      open={openModal}
      modal
      onClose={() => {
        setGameSelected(results?.[0] ?? null);
        setSessionDetailSelected(null);
        setOpenModal(false);
      }}
      closeOnDocumentClick={false}
    >
      <div
        className={`${styles.patientDetailPopup} ${
          sessionDetailSelected && styles.active
        }`}
      >
        <div className={styles.header_popup}>
          <span className="size_large text_gradient">
            {`${t("dashboard_page.practiceResult")} - ${usernamePatient}`}
          </span>
          <button
            className={`${styles.close_btn} size_small`}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            &times;
          </button>
        </div>

        <div className={styles.line}></div>
        <div className={styles.body_popup}>
          <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
            {/* <div className={styles.header_content_popup}>
              <p>{`${t(
                "dashboard_page.practiceResult"
              )} - ${usernamePatient}`}</p>
              <div className={styles.right_header_content_popup}>
                <button className={`${styles.pdf_btn} rounded`}>
                  <span>{`${t("dashboard_page.exportPdf")}`}</span>
                  <img
                    style={{
                      objectFit: "cover",
                    }}
                    src={PdfIcon}
                    alt="PDF icon"
                  />
                </button>

                <div className={styles.vertical_line}></div>

                <button className={`${styles.calendar_btn} rounded`}>
                  <span>Sep 17, 2023</span>
                  <img
                    style={{
                      objectFit: "cover",
                    }}
                    src={CalendarIcon}
                    alt="Calendar icon"
                  />
                </button>
              </div>
            </div> */}

            {results && (
              <>
                <div className={styles.content_popup}>
                  <div className={styles.left_content}>
                    <div className={`${styles.game_item} button_gradient`}>
                      GAME
                    </div>
                    {results &&
                      results.map((game, index) => (
                        <div
                          key={index}
                          className={`${styles.game_item} ${
                            game.id === gameSelected.id
                              ? `${styles.active}`
                              : ""
                          }`}
                          onClick={() => {
                            setGameSelected(game);
                            setSessionDetailSelected(null);
                          }}
                        >
                          {game.name}
                        </div>
                      ))}
                  </div>
                  {renderGameDetails()}
                </div>
              </>
            )}

            {!results && (
              <div className={styles.table_nodata}>
                <img src={NoDataImg} alt="nodata"></img>
                <span>{t("table.noData")}</span>
              </div>
            )}
          </div>

          <div
            className={`${styles.session_detail} ${
              sessionDetailSelected && styles.active
            }`}
          >
            <button
              onClick={() => {
                setSessionDetailSelected(null);
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                src={LeftArrowIcon}
                alt="LeftArrowIcon"
              />
              <span>{t("table.viewDetails")}</span>
            </button>

            {sessionDetailSelected && renderSessionDetails()}
          </div>
        </div>
      </div>
    </Popup>
  );
}

PatientDetailPopup.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export default PatientDetailPopup;
