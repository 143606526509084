export function getUserDataFromPlayFab({
  titleId = window._env_.REACT_APP_TITLE_ID,
  playFabId,
  sessionTicket,
  keys = [],
  getUserDataCallback,
}) {
  const request = {
    TitleId: titleId,
    PlayFabId: playFabId,
    Keys: keys,
  };

  fetch(`https://${titleId}.playfabapi.com/Client/GetUserData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Authorization": sessionTicket,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof getUserDataCallback === "function") {
        getUserDataCallback(result);
      }
    });
}

export function getUserPublisherDataFromPlayFab({
  titleId = window._env_.REACT_APP_TITLE_ID,
  playFabId,
  sessionTicket,
  keys = [],
  getUserPublisherDataCallback,
}) {
  const request = {
    TitleId: titleId,
    PlayFabId: playFabId,
    Keys: keys,
  };

  fetch(`https://${titleId}.playfabapi.com/Client/GetUserPublisherData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Authorization": sessionTicket,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof getUserPublisherDataCallback === "function") {
        getUserPublisherDataCallback(result);
      }
    });
}

export function updateUserDataToPlayFab({
  titleId = window._env_.REACT_APP_TITLE_ID,
  sessionTicket,
  data = {},
  keysToRemove = [],
  permission = "public",
  updateUserDataCallback,
}) {
  const request = {
    Data: data,
    KeysToRemove: keysToRemove,
    Permission: permission,
  };

  fetch(`https://${titleId}.playfabapi.com/Client/UpdateUserData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Authorization": sessionTicket,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof updateUserDataCallback === "function") {
        updateUserDataCallback(result);
      }
    });
}

export function updateUserPublisherDataToPlayFab({
  titleId = window._env_.REACT_APP_TITLE_ID,
  sessionTicket,
  data = {},
  keysToRemove = [],
  permission = "public",
  customTags = {},
  updateUserPublisherDataCallback,
}) {
  const request = {
    CustomTags: customTags,
    Data: data,
    KeysToRemove: keysToRemove,
    Permission: permission,
  };

  fetch(`https://${titleId}.playfabapi.com/Client/UpdateUserPublisherData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Authorization": sessionTicket,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof updateUserPublisherDataCallback === "function") {
        updateUserPublisherDataCallback(result);
      }
    });
}

export function getGroupMembersFromPlayFab({
  titleId = window._env_.REACT_APP_TITLE_ID,
  entityToken,
  groupId,
  getGroupMembersCallback,
}) {
  const request = {
    Group: {
      Id: groupId,
      Type: "group",
    },
  };

  fetch(`https://${titleId}.playfabapi.com/Group/ListGroupMembers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-EntityToken": entityToken,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof getGroupMembersCallback === "function") {
        getGroupMembersCallback(result);
      }
    });
}

export function listGroupApplications({
  titleId = window._env_.REACT_APP_TITLE_ID,
  entityToken,
  groupId,
  customTags = null,
  listCallback,
}) {
  const request = {
    Group: {
      Id: groupId,
      Type: "group",
    },
  };

  if (customTags) {
    request.CustomTags = customTags;
  }

  fetch(`https://${titleId}.playfabapi.com/Group/ListGroupApplications`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-EntityToken": entityToken,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof listCallback === "function") {
        listCallback(result);
      }
    });
}

export function acceptGroupApplication({
  titleId = window._env_.REACT_APP_TITLE_ID,
  entityToken,
  groupId,
  applicantEntity,
  customTags = null,
  acceptCallback,
}) {
  const request = {
    Group: {
      Id: groupId,
      Type: "group",
    },
    Entity: {
      Id: applicantEntity.Id,
      Type: applicantEntity.Type,
    },
  };

  if (customTags) {
    request.CustomTags = customTags;
  }

  fetch(`https://${titleId}.playfabapi.com/Group/AcceptGroupApplication`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-EntityToken": entityToken,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof acceptCallback === "function") {
        acceptCallback(result);
      }
    });
}

export function acceptAllGroupApplications({
  titleId = window._env_.REACT_APP_TITLE_ID,
  entityToken,
  groupId,
  applications,
  customTags = null,
  individualAcceptCallback = null, // Callback cho mỗi lần chấp nhận riêng lẻ
  acceptCallback, // Callback khi hoàn tất việc chấp nhận
}) {
  // Tạo mảng các promise để chấp nhận tất cả yêu cầu
  const acceptPromises = applications.map((application) => {
    return new Promise((resolve, reject) => {
      acceptGroupApplication({
        titleId,
        entityToken,
        groupId,
        applicantEntity: application.Entity.Key,
        customTags,
        acceptCallback: (result) => {
          if (typeof individualAcceptCallback === "function") {
            individualAcceptCallback(result);
          }
          resolve(result);
        },
      });
    });
  });

  Promise.all(acceptPromises)
    .then((results) => {
      if (typeof acceptCallback === "function") {
        acceptCallback(results, null); // Gọi hàm callback với dữ liệu nếu chấp nhận tất cả yêu cầu thành công
      }
    })
    .catch((error) => {
      if (typeof acceptCallback === "function") {
        acceptCallback(null, error); // Gọi hàm callback với lỗi nếu chấp nhận thất bại
      }
    });
}

export function applyToGroup({
  titleId = window._env_.REACT_APP_TITLE_ID,
  entityToken,
  groupId,
  autoAcceptOutstandingInvite = true,
  entity = null,
  applyCallback,
}) {
  const request = {
    Group: {
      Id: groupId,
      Type: "group",
    },
    AutoAcceptOutstandingInvite: autoAcceptOutstandingInvite,
  };

  if (entity) {
    request.Entity = entity;
  }

  fetch(`https://${titleId}.playfabapi.com/Group/ApplyToGroup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-EntityToken": entityToken,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof applyCallback === "function") {
        applyCallback(result);
      }
    });
}
