import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";
import { generateQueryParam } from "utils/urlEncode";

const initialState = {
  gamesOfPatient: null,
  metadataGamesOfPatientPage: null,
};

const DATA_SAMPLE = {
  pagination: {
    totalResult: 2,
    limit: 20,
    page: 1,
    totalPage: 1,
  },
  data: [
    {
      id: "game1",
      name: "Tro choi 1",
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      duration: 120,
      lookUp: 10,
      lookDown: 20,
    },
    {
      id: "game2",
      name: "Tro choi 2",
      description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      duration: 100,
      lookUp: 8,
      lookDown: 9,
    },
  ],
};

export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGamesOfPatient.pending, (state, action) => {})
      .addCase(getGamesOfPatient.fulfilled, (state, action) => {
        state.gamesOfPatient = action.payload.data;
        state.metadataGamesOfPatientPage = action.payload.pagination;
      })
      .addCase(getGamesOfPatient.rejected, (state, action) => {
        state.gamesOfPatient = DATA_SAMPLE.data;
        state.metadataGamesOfPatientPage = DATA_SAMPLE.pagination;
      });
  },
});

export const getGamesOfPatient = createAsyncThunk(
  "games/getGamesOfPatient",
  async (params) => {
    try {
      const { patientId, ...otherParams } = params;

      const queryStringPart = generateQueryParam(otherParams);
      const respone = await axiosInstance.get(
        `/games/${patientId}${queryStringPart}`,
        {}
      );
      return respone.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export default gamesSlice.reducer;
