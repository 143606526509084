import { formatTimeV1 } from "utils/time";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";
import ClockImg from "assets/img/clock.webp";

const GameOverview = ({ name, totalSpentTime }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.game_overview}>
      <div className={styles.game_overview_title}>{name}</div>

      <div className={styles.totalSpentTime}>
        <img alt="clock" src={ClockImg}></img>
        <div className={styles.time}>
          <span>{t("table.totalTime")}</span>
          <span>{formatTimeV1(totalSpentTime)}</span>
        </div>
      </div>
    </div>
  );
};

export default GameOverview;
