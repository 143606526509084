import { Outlet, useLocation } from "react-router-dom";
import { useMemo } from "react";
import BreadcrumbCus from "components/BreadcrumbCus/BreadcrumbCus";
import Header from "components/Header/Header";
import useUpdateUserDataFromPlayfab from "hooks/useUpdateUserDataFromPlayfab";

function MasterLayout() {
  const location = useLocation();
  const pathsNotShowHeader = ["/login"];

  const pathname = location.pathname;

  useUpdateUserDataFromPlayfab();

  const visibleHeader = useMemo(() => {
    return !pathsNotShowHeader.some((path) => pathname.startsWith(path));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      {visibleHeader && <Header />}
      <div
        style={{
          width: "100%",
          overflow: "hidden",
          height: visibleHeader ? "calc(100vh - 3.6875vw)" : "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {visibleHeader && <BreadcrumbCus />}
        <div style={{ flexGrow: 1, overflow: "auto" }}>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default MasterLayout;
