import { LANGUAGE_CODES, LANGUAGE_NAMES } from "helps/Languages";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Popover from "components/Popover/Popover";

import ArrowDownIcon from "assets/icons/arrow-down.svg";
import EditIcon from "assets/icons/edit.svg";
import CheckIcon from "assets/icons/check.svg";
import LanguageIcon from "assets/icons/language.svg";
import MiraboImg from "assets/img/mirabo-logo.webp";
import UserLogoIcon from "assets/icons/user.webp";
import { FiLogOut } from "react-icons/fi";

import styles from "./Header.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const playFabUser = JSON.parse(localStorage.getItem("playFabUser"));

  const patientsRedux = useSelector((state) => state.patientsRedux);

  const [isDropdownLanguageOpen, setIsDropdownLanguageOpen] = useState(false);
  const [anchorElRegion, setAnchorElRegion] = useState(null);
  const [anchorProfile, setAnchorProfile] = useState(null);

  const handleRegionClick = (e) => {
    setAnchorElRegion(e.currentTarget);
    setIsDropdownLanguageOpen(true);
  };

  const handleCloseRegion = () => {
    setAnchorElRegion(null);
    setIsDropdownLanguageOpen(false);
  };

  const handleProfileClick = (e) => {
    setAnchorProfile(e.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorProfile(null);
  };

  const onChangeLanguage = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("vtg_la", value);
  };

  const openRegion = Boolean(anchorElRegion);
  const idRegion = openRegion ? "region-popover" : undefined;

  const openProfile = Boolean(anchorProfile);
  const idProfile = openProfile ? "profile-popover" : undefined;

  return (
    <div className={styles.header_wrapper}>
      <div className={styles.left}>
        <img src={MiraboImg} alt="Mirabo" />
      </div>

      <div className={styles.right}>
        {location.pathname !== "/dashboard/create-dashboard" &&
          patientsRedux.patientsOfDoctor?.length > 0 && (
            <>
              <button
                className={`${styles.edit_dashboard_btn} rounded`}
                onClick={() => {
                  navigate("/dashboard/create-dashboard");
                }}
              >
                <span style={{ textWrap: "nowrap" }}>
                  {t("button.editDashboard")}
                </span>
                <img src={EditIcon} alt="t" />
              </button>
              <div className={styles.line}></div>
            </>
          )}

        <button
          className={`${styles.language_btn} rounded`}
          onClick={handleRegionClick}
        >
          <img
            style={{
              objectFit: "cover",
            }}
            src={LanguageIcon}
            alt="Globe icon"
          />
          <span className={`size_small`} style={{ textWrap: "nowrap" }}>
            {LANGUAGE_NAMES[localStorage.getItem("vtg_la") ?? "ja"]}
          </span>
          <img src={ArrowDownIcon} alt="t" />
        </button>
        <Popover
          id={idRegion}
          open={openRegion}
          anchorEl={anchorElRegion}
          onClose={handleCloseRegion}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div
            className={`${styles.language_options} ${
              isDropdownLanguageOpen ? styles.open : styles.closed
            }`}
          >
            {Object.entries(LANGUAGE_CODES).map(([key, value]) => (
              <button
                key={key}
                className={`${styles.language_option} ${
                  i18n.language === value ? styles.selected : ""
                }`}
                onClick={() => onChangeLanguage(value)}
              >
                <span>{LANGUAGE_NAMES[value]}</span>
                {i18n.language === value && <img src={CheckIcon} alt="icon" />}
              </button>
            ))}
          </div>
        </Popover>

        <div className={styles.line}></div>

        <button
          className={`${styles.profile_btn} rounded`}
          onClick={handleProfileClick}
        >
          <img
            style={{
              objectFit: "cover",
            }}
            src={UserLogoIcon}
            alt="Avatar"
            className="rounded"
          />
          <span className={`size_small`} style={{ textWrap: "nowrap" }}>
            {`ID: ${playFabUser?.Username ?? ""}`}
          </span>
          <img src={ArrowDownIcon} alt="t" />
        </button>

        <Popover
          id={idProfile}
          open={openProfile}
          anchorEl={anchorProfile}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className={styles.profile_popover}>
            <span>{`${t("header.hello")}, ${
              playFabUser?.Username ?? ""
            }!`}</span>
            <button
              className={styles.logout_btn}
              onClick={() => {
                localStorage.removeItem("playFabUser");
                navigate("/login");
              }}
            >
              <FiLogOut style={{transform: "translateY(0.1vw)"}}/>
              <span>{t("header.logout")}</span>
            </button>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default Header;
