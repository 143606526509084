import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en/en";
import translationJA from "./ja/ja";
import translationVI from "./vi/vi";

const resources = {
  en: {
    translation: { ...translationEN },
  },
  vi: {
    translation: { ...translationVI },
  },
  ja: {
    translation: { ...translationJA },
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "vi",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
