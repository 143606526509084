import { setPatientsOfDoctorData } from "appdata/patients/patientsSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLoader } from "contexts/loader-context/LoaderContext";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import SearchBar from "components/SearchBar/SearchBar";

import AvatarGroupImg from "assets/img/avatar-group.webp";

import {
  getUserDataFromPlayFab,
  updateUserDataToPlayFab,
} from "utils/playFabApi";

import { useNavigate } from "react-router-dom";
import { arraysEqual } from "utils/func";
import styles from "./AddPatientPopup.module.scss";

function AddPatientPopup({ openCreateModal, setOpenCreateModal }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { toggleLoading } = useLoader();

  const playFabUser = JSON.parse(localStorage.getItem("playFabUser"));

  const patientsRedux = useSelector((state) => state.patientsRedux);
  const [valueSearch, setValueSearch] = useState("");
  const [initialSelectedPatients, setInitialSelectedPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);

  const filteredUsers =
    valueSearch === ""
      ? patientsRedux.patients.filter(
          (patient) =>
            patient.data.Data["username"]?.Value === undefined ||
            patient.data.Data["username"].Value !== playFabUser.Username // Loại bỏ user hiện tại nếu có username
        )
      : patientsRedux.patients.filter(
          (patient) =>
            (patient.data.Data["name"]?.Value.toLowerCase().includes(
              valueSearch.toLowerCase()
            ) ||
              patient.data.Data["username"]?.Value.toLowerCase().includes(
                valueSearch.toLowerCase()
              )) &&
            (patient.data.Data["username"]?.Value === undefined || // Nếu không có username, không so sánh
              patient.data.Data["username"].Value !== playFabUser.Username) // Nếu có username, loại bỏ user hiện tại
        );

  const handleSelectPatient = (patientId) => {
    setSelectedPatients((prevSelected) =>
      prevSelected.includes(patientId)
        ? prevSelected.filter((id) => id !== patientId)
        : [...prevSelected, patientId]
    );
  };

  const updateUserDataCallback = (response) => {
    if (response.status === "OK") {
      const getUserDataCallback = (result) => {
        if (result.status === "OK") {
          dispatch(
            setPatientsOfDoctorData(JSON.parse(result.data.Data.patients.Value))
          );
          toggleLoading(false);
          setOpenCreateModal(false);
          navigate("/dashboard");
        } else {
          console.log(result?.errorMessage);
        }
      };
      getUserDataFromPlayFab({
        playFabId: playFabUser.PlayFabId,
        sessionTicket: playFabUser.SessionTicket,
        keys: ["patients"],
        getUserDataCallback,
      });
    } else {
      toggleLoading(false);
      toast.error("Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSave = () => {
    console.log(selectedPatients);
    if (arraysEqual(selectedPatients, initialSelectedPatients)) {
      setOpenCreateModal(false);
      return;
    }

    toggleLoading(true);

    const members = selectedPatients.map((el) => {
      return {
        title_id: el,
        master_id: patientsRedux.patients.find((e) => e.title_id === el).id,
      };
    });
    updateUserDataToPlayFab({
      sessionTicket: playFabUser.SessionTicket,
      data: { patients: JSON.stringify(members) },
      updateUserDataCallback,
    });
  };

  useEffect(() => {
    if (!patientsRedux.patientsOfDoctor) return;
    // Set the initial selected patients based on patientsRedux.patientsOfDoctor
    setSelectedPatients(
      patientsRedux.patientsOfDoctor.map((patient) => patient.title_id)
    );
    setInitialSelectedPatients(
      patientsRedux.patientsOfDoctor.map((patient) => patient.title_id)
    );
  }, [patientsRedux.patientsOfDoctor]);

  return (
    <Popup
      style={{ zIndex: 1001 }}
      open={openCreateModal}
      modal
      onClose={() => {
        setOpenCreateModal(false);
      }}
      closeOnDocumentClick={false}
    >
      <div className={styles.addPatientPopup}>
        <div className={styles.header_popup}>
          <img alt="avt" src={AvatarGroupImg} />
          <span className="size_large text_gradient">
            {t("button.addPatientToTheWidget")}
          </span>
          <p>{`"${t("create_dashboard_page.addNewPatientsMessage")}"`}</p>
        </div>

        <div
          style={{
            padding: "0 6.25vw",
            margin: "1.25vw 0",
          }}
        >
          <SearchBar
            valueSearch={valueSearch}
            setValueSearch={setValueSearch}
            containerStyle={{
              width: "100%",
              height: "3.125vw",
              borderRadius: "1000px",
              border: "1px solid #EFF0F6",
            }}
          ></SearchBar>
        </div>

        <div style={{ overflow: "hidden", flex: "1", marginBottom: "2vw" }}>
          <div className={styles.table_scroll}>
            <table>
              <thead>
                <tr>
                  <th>{t("common_text.patientId")}</th>
                  <th>{t("common_text.fullName")}</th>
                  <th>{t("table.select")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((patient) => (
                  <tr key={patient.title_id}>
                    <td>
                      {patient.data.Data.username?.Value ?? patient.title_id}
                    </td>
                    <td style={{ color: "#127ec5" }}>
                      {patient.data.Data.name?.Value ?? ""}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedPatients.includes(patient.title_id)}
                        onChange={() => handleSelectPatient(patient.title_id)}
                        className={styles.custom_checkbox}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.footer_popup}>
          <button
            className="button_secondary rounded"
            onClick={() => setOpenCreateModal(false)}
          >
            {t("button.cancel")}
          </button>
          <button
            className="button_primary rounded"
            onClick={handleSave}
            disabled={selectedPatients.length === 0}
          >
            {t("button.save")}
          </button>
        </div>
      </div>
    </Popup>
  );
}

AddPatientPopup.propTypes = {
  openCreateModal: PropTypes.bool,
  setOpenCreateModal: PropTypes.func,
};

export default AddPatientPopup;
