import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";

import GameDetailLayout from "../GameDetailLayout";

function MathDetail() {
  const { t } = useTranslation();
  const { gameSelected } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "spentTime", label: t("table.spentTime") },
    { key: "calculationDifficulty", label: t("game.calculationDifficulty") },
    { key: "numberOfCorrections", label: t("game.numberOfCorrections") },
    { key: "numberOfIncorrections", label: t("game.numberOfIncorrections") },
  ];

  const convertDataToDataTable = (_data) => {
    if (_data.length === 0) return null;

    return _data.map((session, index) => {
      return {
        order: index + 1,
        spentTime: session.spentTime.toFixed(2),
        calculationDifficulty: session.calculationDifficulty,
        numberOfCorrections: session.numberOfCorrections,
        numberOfIncorrections: session.numberOfIncorrections,
      };
    });
  };

  const totalSpentTime = gameSelected.result.mathGameDataSesions
    .reduce((total, session) => total + session.spentTime, 0)
    .toFixed(0);

  return (
    <GameDetailLayout
      gameName={gameSelected.name}
      totalSpentTime={totalSpentTime}
      headTabs={headTabs}
      data={gameSelected.result.mathGameDataSesions}
      dataConverter={convertDataToDataTable}
    />
  );
}

export default MathDetail;
