import PropTypes from "prop-types";

import styles from "./TableSticky.module.scss";

function TableSticky({ headTabs, data, headerStyles = [], columnStyles = [] }) {
  return (
    <div className={styles.table_scroll}>
      <table>
        <thead>
          <tr>
            {headTabs.map((tab) => {
              const style =
                headerStyles.find((style) => style.key === tab.key) || {};
              return (
                <th key={tab.key} align="center" style={style}>
                  {tab.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item.index}>
              {headTabs.map((tab) => {
                const style =
                  columnStyles.find((style) => style.key === tab.key) || {};
                return (
                  <td key={tab.key} style={style}>
                    {item[tab.key]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

TableSticky.propTypes = {
  headTabs: PropTypes.array,
  data: PropTypes.array,
  headerStyles: PropTypes.array,
  columnStyles: PropTypes.array,
};

export default TableSticky;
