import { usePatient } from "contexts/patient-context/PatientContext";
import { useTranslation } from "react-i18next";
import TableSticky from "components/TableSticky/TableSticky";

function NeckThrowSessionDetail() {
  const { t } = useTranslation();
  const { sessionDetailSelected } = usePatient();

  const headTabs = [
    { key: "order", label: t("table.order") },
    { key: "type", label: t("game.type") },
    { key: "maxAngle", label: t("game.maxAngle") },
    { key: "holdTime", label: t("game.holdTime") },
  ];

  const convertDataToDataTable = (_data) => {
    if (_data.length === 0) return null;
    return _data.map((el, index) => {
      return {
        order: index + 1,
        type: el.name === "HeadUp" ? t("game.headUp") : t("game.headDown"),
        maxAngle: el.maxAngle.toFixed(2),
        holdTime: el.holdTime.toFixed(2),
      };
    });
  };

  return (
    <TableSticky
      headTabs={headTabs}
      data={convertDataToDataTable(sessionDetailSelected.lookDatas)}
    ></TableSticky>
  );
}

export default NeckThrowSessionDetail;
