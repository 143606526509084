import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getUserDataFromPlayFab } from "utils/playFabApi";
import PropTypes from "prop-types";
import { useLoader } from "contexts/loader-context/LoaderContext";

const PatientContext = createContext();

export const PatientProvider = ({ children }) => {
  const { toggleLoading } = useLoader();
  const playFabUser = JSON.parse(localStorage.getItem("playFabUser"));

  const [usernamePatient, setUsernamePatient] = useState(null);
  const [idPatient, setIdPatient] = useState(null);
  const [results, setResults] = useState(null);
  const [gameSelected, setGameSelected] = useState(null);
  const [sessionDetailSelected, setSessionDetailSelected] = useState(null);

  useEffect(() => {
    if (!idPatient) return;
    const getUserDataCallback = (result) => {
      if (result.status === "OK") {
        const gameResults = Object.keys(result.data.Data)
          .filter((key) => key.endsWith("_result")) // Lọc các key có đuôi _result
          .map((key) => {
            const gameNameSnakeCase = key.replace("_result", ""); // Loại bỏ _result
            const gameName = gameNameSnakeCase
              .split("_") // Tách chuỗi snake_case
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Viết hoa từ đầu tiên
              .join(" "); // Ghép lại thành chuỗi với dấu cách
            return {
              id: gameNameSnakeCase,
              name: gameName === "Tmt" ? "TMT-A" : gameName,
              result: JSON.parse(result.data.Data[key].Value),
            };
          });

        toggleLoading(false);
        setResults(gameResults.length > 0 ? gameResults : null);
        setGameSelected(gameResults.length > 0 ? gameResults[0] : null);
      } else {
        console.log(result?.errorMessage);
      }
    };

    if (idPatient) {
      getUserDataFromPlayFab({
        playFabId: idPatient,
        sessionTicket: playFabUser.SessionTicket,
        getUserDataCallback: getUserDataCallback,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idPatient]);

  const contextValue = useMemo(
    () => ({
      usernamePatient,
      setUsernamePatient,
      idPatient,
      setIdPatient,
      results,
      setResults,
      gameSelected,
      setGameSelected,
      sessionDetailSelected,
      setSessionDetailSelected,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usernamePatient, idPatient, results, gameSelected, sessionDetailSelected]
  );

  return (
    <PatientContext.Provider value={contextValue}>
      {children}
    </PatientContext.Provider>
  );
};

PatientProvider.propTypes = {
  children: PropTypes.any,
};

export const usePatient = () => {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error("usePatient must be used within a PatientProvider");
  }
  return context;
};
