import PropTypes from "prop-types";
import { Pagination } from "antd";

const PAGE_SIZE = 10;

const PaginationCus = ({ metadataPage, onClick = () => {}, styles }) => {
  const { currentPage, numberOfItems, pageSize } = metadataPage;
  const handleClickPageButton = (page, pageSize) => {
    const pageParam = { page: page, pageSize: PAGE_SIZE };
    onClick(pageParam);
  };

  return (
    numberOfItems > pageSize && (
      <div style={{ ...styles }}>
        <Pagination
          currentPage={currentPage + 1}
          defaultCurrent={1}
          total={numberOfItems}
          onChange={handleClickPageButton}
          align="end"
        />
      </div>
    )
  );
};

PaginationCus.propTypes = {
  metadataPage: PropTypes.any,
  onClick: PropTypes.func,
  styles: PropTypes.any,
};

export default PaginationCus;
