import React, { createContext, useState, useContext } from "react";
import styles from "./LoaderContext.module.scss";
import BeatLoader from "react-spinners/BeatLoader";

// Tạo context
const LoaderContext = createContext();

// Hook để sử dụng context dễ dàng trong các component
export const useLoader = () => useContext(LoaderContext);

// Component provider để bọc ứng dụng và xử lý loading overlay
export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  // Hàm để toggle trạng thái loading
  const toggleLoading = (state) => setIsLoading(state);

  return (
    <LoaderContext.Provider value={{ isLoading, toggleLoading }}>
      {children}
      {isLoading && (
        <div className={styles.overlay}>
          <BeatLoader color="var(--blue)"></BeatLoader>
        </div>
      )}
    </LoaderContext.Provider>
  );
};
